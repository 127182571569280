import { Helmet } from "react-helmet";
import PageBanner from "../../components/body/PageBanner";
import toast from "react-hot-toast";
import contact_img from "../../assets/images/others/contact.jpg";
import { APP_NAME } from "../../utils/appName";

function Contact() {
  return (
    <>
      <Helmet>
        <title>
          Contacto - {APP_NAME}
        </title>
      </Helmet>
      <PageBanner title="Contacto" />
      <div className="contact-area grey-bg-2 z-index pt-80 pb-80">
        <div className="container">
          <div className="row">
            <div className="tp-history-title-box mb-60 text-center">
              <span className="tp-section-subtitle-2">
                ¿Tienes alguna duda?
              </span>
              <h4 className="tp-section-title text-decoration-underline">
                Contáctanos
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-5 col-lg-6 d-lg-inline-block d-none">
              <div className="contact-left-thumb mb-60 p-relative">
                <img src={contact_img} alt="contact" />
              </div>
              <div className="contact-social">
                <span>Redes Sociales :</span>
                <a
                  href="https://www.facebook.com/profile.php?id=100089087867207"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook-f" />
                </a>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6">
              <div className="contact-wrapper">
                <form
                  action="https://formsubmit.co/munipallan2023@gmail.com"
                  method="POST"
                  autoComplete="on"
                  onSubmit={() => {
                    toast.success("Mensaje enviado correctamente.");
                  }}
                  onError={() => {
                    toast.error("Error al enviar el mensaje.");
                  }}
                >
                  <input
                    type="hidden"
                    name="_subject"
                    value="Nuevo mensaje de contacto"
                  ></input>
                  <input
                    type="hidden"
                    name="_cc"
                    value="jfernandezh19_2@unc.edu.pe"
                  ></input>
                  <input type="hidden" name="_template" value="table"></input>
                  <input type="hidden" name="_captcha" value="false"></input>
                  <input
                    type="text"
                    name="_honey"
                    style={{ display: "none" }}
                  ></input>
                  <input
                    type="hidden"
                    name="_next"
                    value="https://www.munilalibertaddepallan.gob.pe/contacto"
                  ></input>
                  <div className="tp-contact-2-form">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6">
                        <div className="tp-contact-2-input p-relative">
                          <input
                            type="text"
                            name="Nombre"
                            placeholder="Nombre"
                            autoComplete="name"
                            required
                          />
                          <span>
                            <i className="fal fa-user" />
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <div className="tp-contact-2-input p-relative">
                          <input
                            type="email"
                            name="Correo"
                            placeholder="Correo"
                            autoComplete="email"
                            required
                          />
                          <span>
                            <i className="fa-light fa-envelope" />
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12">
                        <div className="tp-contact-2-input p-relative">
                          <input
                            type="text"
                            name="Asunto"
                            placeholder="Asunto"
                            autoComplete="subject"
                            required
                          />
                          <span>
                            <i className="fa-sharp fa-light fa-circle-info" />
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="tp-contact-2-input p-relative">
                          <textarea
                            name="Mensaje"
                            placeholder="Mensaje"
                            autoComplete="message"
                            required
                            defaultValue={""}
                            maxLength={400}
                          />
                          <span className="icon-1">
                            <i className="fa-light fa-pen" />
                          </span>
                        </div>
                      </div>
                      <div className="tp-contact-2-btn">
                        <button className="tp-btn-xl green-anim">
                          Enviar Mensaje
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 d-lg-none d-block pt-80">
              <div className="contact-left-thumb mb-60 p-relative">
                <img src={contact_img} alt="contact" />
              </div>
              <div className="contact-social">
                <span>Redes Sociales :</span>
                <a
                  href="https://www.facebook.com/profile.php?id=100089087867207"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook-f" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
