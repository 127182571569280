import { Helmet } from "react-helmet";
import PageBanner from "../../components/body/PageBanner";
import Description from "../../components/muni/Description";
import district_logo from "../../assets/images/history/history-4.png";
import { APP_NAME } from "../../utils/appName";
import pdf from "../../assets/documents/otros/CREACION DEL DISTRITO DE PALLAN.pdf";

function DistrictCreation() {
  return (
    <>
      <Helmet>
        <title>Ley de creación distrital - {APP_NAME}</title>
      </Helmet>
      <PageBanner
        title="Municipalidad"
        subtitle="Ley de creación distrital"
        parent_link="/municipalidad"
      />
      <Description
        mainImage={district_logo}
        span="Acerca del distrito"
        title="Ley de creación distrital"
        subtitle="Ley 26262"
        description="En 1993, el distrito de La Libertad de Pallán fue oficialmente creado mediante la Ley 26262, promulgada el 23 de diciembre de 1993, durante el gobierno de Alberto Fujimori. Esta legislación consolidó la independencia de Pallán como un distrito autónomo, marcando un hito significativo en su historia moderna."
        asset_link={pdf}
      />
    </>
  );
}

export default DistrictCreation;
