import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/muni/logo.png";
import { scrollToTop } from "../../utils/scrollUtils";
import { useState } from "react";
import { menuItems } from "../../utils/menuItems";
import { APP_NAME } from "../../utils/appName";

function SideBarMenu({ show, closeSideBar }) {
  const location = useLocation();

  const [dropdownStates, setDropdownStates] = useState({
    1: false,
    2: false,
    3: false,
  });

  const toggleDropdown = (index) => {
    setDropdownStates({
      ...dropdownStates,
      [index]: !dropdownStates[index],
    });
  };

  const closeAll = () => {
    scrollToTopLink();
    setDropdownStates({
      ...dropdownStates,
      1: false,
      2: false,
      3: false,
    });
  };

  const closeSideDrop = () => {
    closeSideBar();
    setDropdownStates({
      ...dropdownStates,
      1: false,
      2: false,
      3: false,
    });
  };

  const scrollToTopLink = () => {
    scrollToTop();
    closeSideBar();
  };

  const generateMenuItem = (item, index, parentPath = "") => {
    const fullPath = parentPath + item.path;
    const currentPath = location.pathname;
    return (
      <li
        key={index}
        className={`has-dropdown ${
          dropdownStates[index] ? "dropdown-opened" : ""
        }`}
      >
        <Link
          className={
            currentPath === fullPath
              ? "active"
              : item.submenu && currentPath.includes(fullPath)
              ? "active"
              : ""
          }
          to={item.path}
          onClick={
            item.submenu
              ? (e) => {
                  e.preventDefault();
                }
              : closeAll
          }
        >
          <Link to={item.path} onClick={closeAll} className="d-flex">
            {item.text}
          </Link>
          {item.submenu && (
            <button
              className={`dropdown-toggle-btn ${
                dropdownStates[index] ? "dropdown-opened" : ""
              }`}
              onClick={() => toggleDropdown(index)}
            >
              <i className="fal fa-angle-right" />
            </button>
          )}
        </Link>
        {item.submenu && (
          <ul
            className="tp-submenu submenu"
            style={{ display: dropdownStates[index] ? "block" : "none" }}
          >
            {item.submenu.map((subitem, subindex) => (
              <li key={subindex}>
                <Link
                  to={!subitem.target ? fullPath + subitem.path : subitem.path}
                  target={subitem.target}
                  onClick={() => {
                    if (!subitem.target) {
                      closeAll();
                    } else {
                      closeSideDrop();
                    }
                  }}
                >
                  {subitem.text}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  };

  return (
    <>
      <div className="tpoffcanvas-area">
        <div className={`tpoffcanvas ${show ? "opened" : ""}`}>
          <div className="tpoffcanvas__close-btn">
            <button className="close-btn" onClick={closeSideDrop}>
              <i className="fal fa-times" />
            </button>
          </div>
          <div className="tpoffcanvas__logo">
            <Link to="/" onClick={closeAll}>
              <img src={logo} width={50} height={50} alt="logo" />
            </Link>
          </div>
          <div className="tp-main-menu-mobile">
            <nav className="tp-main-menu-content">
              <ul className="tp-onepage-menu">
                {menuItems.map((item, index) => generateMenuItem(item, index))}
              </ul>
            </nav>
          </div>

          <div className="tpoffcanvas__contact-info">
            <div className="tpoffcanvas__contact-title">
              <h5>Contáctanos</h5>
            </div>
            <ul className="list-unstyled">
              <li className="d-flex align-items-start">
                <i className="fa-solid fa-location-dot mt-1 me-2" />
                <span>
                  Jr. La Purísima N° 230 - Plaza de Armas de La Libertad de
                  Pallán
                </span>
              </li>
              <li className="d-flex align-items-start">
                <i className="fa-solid fa-envelope mt-1 me-2"></i>
                <a href="mailto:munipallan2023@gmail.com">
                  munipallan2023@gmail.com
                </a>
              </li>
              <li className="d-flex align-items-start">
                <i className="fa-brands fa-facebook-f mt-1 me-2" />
                <a
                  href="https://www.facebook.com/profile.php?id=100089087867207"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {APP_NAME}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className={`body-overlay ${show ? "apply" : ""}`}
        onClick={closeSideDrop}
      />
    </>
  );
}

export default SideBarMenu;
