import { Link } from "react-router-dom";
import { scrollToTop } from "../../utils/scrollUtils";
import background from "../../assets/images/muni/background.jpg";

function PageBanner({
  title,
  subtitle,
  parent_link,
  subtitle_link = null,
  folder = null,
}) {
  return (
    <>
      <div
        className="breadcrumb__area breadcrumb__overlay breadcrumb__height p-relative fix"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content z-index text-center">
                <h3 className="breadcrumb__title">
                  {subtitle ? (folder ? folder : subtitle) : title}
                </h3>
                <div className="breadcrumb__list">
                  <span>
                    <Link to="/" onClick={scrollToTop}>
                      Inicio
                    </Link>
                  </span>
                  <span className="dvdr">
                    <i className="fa fa-angle-right" />
                  </span>
                  <span>
                    {subtitle ? (
                      <Link to={parent_link} onClick={scrollToTop}>
                        {title}
                      </Link>
                    ) : (
                      title
                    )}
                  </span>
                  {subtitle && !folder && (
                    <>
                      <span className="dvdr">
                        <i className="fa fa-angle-right" />
                      </span>
                      <span>{subtitle}</span>
                    </>
                  )}
                  <span>
                    {subtitle_link && folder && (
                      <>
                        <span className="dvdr">
                          <i className="fa fa-angle-right" />
                        </span>
                        <Link
                          to={parent_link + subtitle_link}
                          onClick={scrollToTop}
                        >
                          {subtitle}
                        </Link>
                      </>
                    )}
                  </span>
                  {folder && (
                    <>
                      <span className="dvdr">
                        <i className="fa fa-angle-right" />
                      </span>
                      <span>{folder}</span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageBanner;
