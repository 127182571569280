import QuickAccess from "./QuickAccess";
import Slider from "./Slider";
import { Helmet } from "react-helmet";
import News from "../news/News";
import Map from "../../components/muni/Map";
import ExternalLinks from "../../components/muni/ExternalLinks";
import { APP_NAME } from "../../utils/appName";

function Home() {
  return (
    <>
      <Helmet>
        <title>Inicio - {APP_NAME}</title>
      </Helmet>
      <Slider />
      <QuickAccess />
      <News />
      <ExternalLinks />
      <Map />
    </>
  );
}

export default Home;
