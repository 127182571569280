import history_1 from "../../assets/images/history/history-2.jpg";
import history_2 from "../../assets/images/history/history-1.jpg";
import history_3 from "../../assets/images/history/history-3.jpeg";
import history_4 from "../../assets/images/history/history-4.png";

function History() {
  return (
    <div className="tp-history-area pt-80 pb-50">
      <div className="container">
        <div className="row">
          <div className="tp-history-title-box mb-60 text-center">
            <span className="tp-section-subtitle-2">
              Una breve reseña historíca
            </span>
            <h4 className="tp-section-title text-decoration-underline">
              Historia del Distrito
            </h4>
          </div>
        </div>
        <div className="row mb-100">
          <div className="col-xl-6 col-lg-6">
            <div className="tp-history-left">
              <div className="tp-history-thumb">
                <img src={history_1} alt="history 1" />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="tp-history-right">
              <div className="tp-history-content space-1">
                <h4 className="tp-inner-title">
                  Donación de la Hacienda Pallán
                </h4>
                <p>
                  En 1710, la Hacienda Pallán fue entregada al Convento de las
                  Madres Concepcionistas por el bisnieto del primer propietario
                  español, a cambio de la promesa de dos misas perpetuas en su
                  honor y en memoria de sus padres. Este evento marcó el inicio
                  de la historia de La Libertad de Pallán como una entidad
                  vinculada al convento.
                </p>
                <span>1710</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-100 order-1 order-lg-0">
          <div className="col-xl-6 col-lg-6">
            <div className="tp-history-right">
              <div className="tp-history-content space-2 text-start text-lg-end">
                <h4 className="tp-inner-title">Levantamiento Campesino</h4>
                <p>
                  En 1946, alrededor de 500 familias campesinas se levantaron en
                  contra del hacendado de la Hacienda Pallán. Motivados por
                  abogados afiliados al partido aprista, este levantamiento
                  generó conflictos violentos, llegando al punto de casi matar a
                  Abel Caballero, el administrador de la hacienda en ese
                  momento. Este episodio representa un momento crucial en la
                  lucha por la independencia de Pallán.
                </p>
                <span className="solid-text">1946</span>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="tp-history-left">
              <div className="tp-history-thumb">
                <img src={history_2} alt="history 2" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-100 order-0 order-lg-1">
          <div className="col-xl-6 col-lg-6">
            <div className="tp-history-left">
              <div className="tp-history-thumb">
                <img src={history_3} alt="history 3" />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="tp-history-right">
              <div className="tp-history-content space-1">
                <h4 className="tp-inner-title">Transformación en Pueblo</h4>
                <p>
                  En 1959, gracias a la persistente lucha de los pobladores,
                  entre ellos Juan de la Rosa, se logró que la Hacienda Pallán
                  dejara de existir y se transformara en un pueblo
                  independiente. Abraham Delgado lideró este proceso, marcando
                  un hito importante en la historia de Pallán, al lograr su
                  autonomía 10 años antes de la Reforma Agraria del Presidente
                  Velasco Alvarado en 1969.
                </p>
                <span>1959</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="tp-history-right">
              <div className="tp-history-content space-2 text-start text-lg-end">
                <h4 className="tp-inner-title">Creación del Distrito</h4>
                <p>
                  En 1993, el distrito de La Libertad de Pallán fue oficialmente
                  creado mediante la Ley 26262, promulgada el 23 de diciembre de
                  1993, durante el gobierno de Alberto Fujimori. Esta
                  legislación consolidó la independencia de Pallán como un
                  distrito autónomo, marcando un hito significativo en su
                  historia moderna.
                </p>
                <span className="solid-text">1993</span>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="tp-history-left">
              <div className="tp-history-thumb col-xl-9 col-lg-9">
                <img src={history_4} alt="history 4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default History;
