import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";

function Loader() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simula una carga de página de 1 segundo para propósitos de demostración.
    const fakePageLoad = setTimeout(() => {
      setIsLoading(false); // Cuando la página haya cargado, establece isLoading en false
    }, 1000);

    return () => {
      // Limpia el temporizador cuando el componente se desmonta
      clearTimeout(fakePageLoad);
    };
  }, []);

  return (
    <CSSTransition in={isLoading} timeout={500} classNames="fade" unmountOnExit>
      <div id="loading" className="Loader">
        <div id="loading-center">
          <div id="loading-center-absolute">
            <div className="object" id="object_four" />
            <div className="object" id="object_three" />
            <div className="object" id="object_two" />
            <div className="object" id="object_one" />
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

export default Loader;
