import { Helmet } from "react-helmet";
import PageBanner from "../../components/body/PageBanner";
import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";

import Location from "./Location";
import History from "./History";
import Tourism from "./Tourism";
import { APP_NAME } from "../../utils/appName";

function District() {
  // const ubicacionRef = useRef();
  // const historiaRef = useRef();
  // const turismoRef = useRef();
  // const location = useLocation();

  // const scrollToSection = (ref) => {
  //   if (ref.current) {
  //     ref.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  // useEffect(() => {
  //   // Función para procesar el fragmento de hash
  //   const handleHashChange = () => {
  //     if (window.location.hash === "#ubicacion") {
  //       scrollToSection(ubicacionRef);
  //     } else if (window.location.hash === "#historia") {
  //       scrollToSection(historiaRef);
  //     } else if (window.location.hash === "#turismo") {
  //       scrollToSection(turismoRef);
  //     }
  //   };

  //   // Agregar un escuchador de eventos para el cambio de hash
  //   window.addEventListener("hashchange", handleHashChange);

  //   // Llamar a la función de manejo de hash al cargar la página
  //   handleHashChange();

  //   return () => {
  //     // Eliminar el escuchador de eventos al desmontar el componente
  //     window.removeEventListener("hashchange", handleHashChange);
  //   };
  // }, [location]);
  return (
    <>
      <Helmet>
        <title>Distrito - {APP_NAME}</title>
      </Helmet>
      <PageBanner title="Distrito" />
      {/* <div ref={ubicacionRef} id="ubicacion">
        <Location />
      </div>
      <div ref={historiaRef} id="historia">
        <History />
      </div>
      <div ref={turismoRef} id="turismo">
        <Tourism />
      </div> */}
      <Location />
      <History />
      <Tourism />
    </>
  );
}

export default District;
