import Slider from "react-slick";
import ext_1 from "../../assets/images/external/1.png";
import ext_2 from "../../assets/images/external/2.png";
import ext_3 from "../../assets/images/external/3.png";
import ext_4 from "../../assets/images/external/4.png";
import ext_5 from "../../assets/images/external/5.png";
import ext_6 from "../../assets/images/external/6.png";
import ext_7 from "../../assets/images/external/7.png";
import ext_8 from "../../assets/images/external/8.png";
import ext_9 from "../../assets/images/external/9.png";
import ext_10 from "../../assets/images/external/10.png";

const data = [
  {
    image: ext_1,
    url: "https://www.pronabec.gob.pe/beca-18/",
  },
  {
    image: ext_2,
    url: "https://www.sunat.gob.pe/",
  },
  {
    image: ext_3,
    url: "https://www.gob.pe/reniec",
  },
  {
    image: ext_4,
    url: "https://www.gob.pe/osce",
  },
  {
    image: ext_5,
    url: "https://www.gob.pe/es/institucion/osce/tema/seace",
  },
  {
    image: ext_6,
    url: "https://www.gob.pe/",
  },
  {
    image: ext_7,
    url: "https://www.gob.pe/midis",
  },
  {
    image: ext_8,
    url: "https://elperuano.pe/",
  },
  {
    image: ext_9,
    url: "https://www.gob.pe/gobiernodigital",
  },
  {
    image: ext_10,
    url: "https://www.gob.pe/pension65",
  },
];

function ExternalLinks() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container pt-80 pb-80">
      <div className="tp-brand-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="tp-brand-title-box mb-60 text-center">
                <i className="flaticon-spark" />
                <span className="tp-section-subtitle-2">
                  Enlaces de Interés
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Slider className="row brand-active" {...settings}>
        {data.map((item, index) => (
          <div className="col" key={index}>
            <div className="brand__item">
              <span>
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <img src={item.image} alt="Externo" />
                </a>
              </span>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ExternalLinks;
