import { Helmet } from "react-helmet";
import PageBanner from "../../components/body/PageBanner";
import { Link } from "react-router-dom";

import { scrollToTop } from "../../utils/scrollUtils";

import default_image from "../../assets/images/history/history-4.png";
import { APP_NAME } from "../../utils/appName";

const data = [
  {
    title: "Transparencia",
    link: "https://www.transparencia.gob.pe/enlaces/pte_transparencia_enlaces.aspx?id_entidad=10834",
    target: "_blank",
  },
  {
    title: "TUPA",
    link: "https://www2.congreso.gob.pe/sicr/cendocbib/con4_uibd.nsf/D7BDF7C0AED6A82605257E65005F7D72/$FILE/Guia_para_la_aplicacion_del_TUPA.pdf",
    target: "_blank",
  },
  {
    title: "Mesa de Partes Virtual",
    link: "/servicios/mesa-de-partes-virtual",
  },
  {
    title: "Libro de Reclamaciones",
    link: "/servicios/libro-de-reclamaciones",
  },
];

const ServiceItem = ({ title, link, target }) => (
  <Link
    to={link}
    onClick={() => {
      if (!target) {
        scrollToTop();
      }
    }}
    target={target}
    className="col-xl-4 col-lg-4 col-md-6 mb-30 wow tpfadeUp"
  >
    <div
      className="tp-service-standard-item p-relative"
      style={{
        visibility: "visible",
        animationDuration: "0.9s",
        animationDelay: "0.3s",
        animationName: "tpfadeUp",
      }}
    >
      <div className="tp-service-standard-img">
        <img src={default_image} alt="default_image" />
      </div>
      <div className="tp-service-standard-content-wrap z-index">
        <div className="tp-service-standard-content-main">
          <div className="tp-service-standard-content">
            <span>
              <i className="flaticon-goverment" />
            </span>
            <h4 className="tp-service-standard-title">
              <Link
                to={link}
                onClick={() => {
                  if (!target) {
                    scrollToTop();
                  }
                }}
                target={target}
              >
                {title}
              </Link>
            </h4>
          </div>
          <div className="tp-service-standard-link">
            <Link
              to={link}
              onClick={() => {
                if (!target) {
                  scrollToTop();
                }
              }}
              target={target}
            >
              Ver más <i className="flaticon-right-arrow" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Link>
);

function ServiceList() {
  return (
    <>
      <Helmet>
        <title>Servicios - {APP_NAME}</title>
      </Helmet>
      <PageBanner title="Servicios" />
      <div className="tp-service-standard-area pt-80 pb-50">
        <div className="container">
          <div className="row">
            <div className="mb-30 text-center">
              <span className="tp-section-subtitle-2">
                ¿Qué podemos hacer por ti?
              </span>
              <h4 className="tp-section-title text-decoration-underline">
                Nuestros Servicios
              </h4>
            </div>
          </div>
          <div className="row">
            {data.map((item, index) => (
              <ServiceItem key={index} {...item} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceList;
