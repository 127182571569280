import React from "react";
import { format, parseISO } from "date-fns";
import default_image from "../../assets/images/history/history-4.png";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../utils/scrollUtils";

const getIcon = (type) => {
  switch (type) {
    case "Share":
      return "fa-solid fa-share";
    case "Video":
      return "fa-brands fa-youtube";
    case "Article":
      return "fas fa-eye";
    default:
      return "fa-brands fa-facebook";
  }
};

function Post({
  type,
  message,
  full_picture,
  created_time,
  permalink_url,
  btn_label,
  target,
}) {
  // Formatear la fecha
  function formatDate(date) {
    const parsedDate = parseISO(date);
    return format(parsedDate, "dd/MM/yyyy");
  }
  return (
    <div className="tp-event-3-item col-xl-4 col-lg-4 col-md-6 mb-30">
      <div className="tp-event-3-thumb fix">
        <div className="tp-event-3-thumb fix">
          <img src={full_picture} alt="full_picture" loading="lazy" />
        </div>
      </div>
      <div className="tp-event-3-content-wrap">
        <div className="tp-event-3-content">
          <div className="tp-event-3-meta">
            <span>
              <i className="fa-light fa-calendar-days" />
              {formatDate(created_time)}
            </span>
            <span>
              <i className="fa-solid fa-user" />
              Municipalidad Distrital de Pallán
            </span>
          </div>
          <p
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            {message}
          </p>
        </div>
        <div className="tp-event-3-btn-box d-block d-md-flex">
          {permalink_url ? (
            <Link
              className="tp-btn-border-sm w-100 text-center"
              to={permalink_url}
              target={target}
              onClick={() => {
                if (target !== "_blank") {
                  scrollToTop();
                }
              }}
            >
              <i className={`${getIcon(type)} mr-5`}></i>
              <span>{btn_label}</span>
            </Link>
          ) : (
            <a
              className="tp-btn-border-sm w-100 text-center"
              href={full_picture}
              download
            >
              <i className={`${getIcon(type)} mr-5`}></i>
              <span>{btn_label}</span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

function Posts({ data }) {
  return (
    <div
      className="row"
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      {data.map(
        (post, index) =>
          post.message && (
            <Post
              key={index}
              message={post.message}
              full_picture={
                post.full_picture ? post.full_picture : default_image
              }
              created_time={post.created_time}
              permalink_url={post.permalink_url}
              target={post.target ? post.target : "_blank"}
              btn_label={post.btn_label ? post.btn_label : "Ver en Facebook"}
              type={post.type ? post.type : "Facebook"}
            />
          )
      )}
    </div>
  );
}

export default Posts;
