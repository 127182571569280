import { Link } from "react-router-dom";
import { scrollToTop } from "../../utils/scrollUtils";
import { APP_NAME } from "../../utils/appName";

function Footer() {
  // Función para obtener el año actual
  function getYear() {
    return new Date().getFullYear();
  }

  return (
    <footer>
      {/* footer-top area start */}
      <div className="tp-footer-top-area tp-footer-top-bdr-bottom pt-35 theme-bg-2">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="tp-footer-top-item tp-footer-top-space-1 d-flex align-items-center">
                <div className="tp-footer-top-info-icon">
                  <i className="flaticon-goverment" />
                </div>
                <div className="tp-footer-top-info">
                  <h4 className="tp-footer-top-info-title">Dirección</h4>
                  <span>
                    Jr. La Purísima N° 230 - Plaza de Armas de La Libertad de
                    Pallán
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="tp-footer-top-item tp-footer-top-space-2 d-flex align-items-center">
                <div className="tp-footer-top-info-icon">
                  <i className="flaticon-email" />
                </div>
                <div className="tp-footer-top-info">
                  <h4 className="tp-footer-top-info-title">Correo</h4>
                  <span>
                    <a href="mailto:munipallan2023@gmail.com">
                      munipallan2023@gmail.com
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="tp-footer-top-item tp-footer-top-space-3 d-flex align-items-center">
                <div className="tp-footer-top-info-icon">
                  <i className="fa-brands fa-facebook-f" />
                </div>
                <div className="tp-footer-top-info">
                  <h4 className="tp-footer-top-info-title">Facebook</h4>
                  <span>
                    <a
                      href="https://www.facebook.com/profile.php?id=100089087867207"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {APP_NAME}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer-top area end */}
      {/* copy-right area start */}
      <div className="tp-copyright-area tp-copyright-space theme-bg-2 pt-25 pb-25">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="tp-copyright-left-text text-center">
              <p>
                © Copyright {getYear()}{" "}
                <Link to="/" onClick={scrollToTop}>
                  {APP_NAME}
                </Link>
              </p>
              <br />
              <Link to="/politica-de-privacidad" target="_blank">
                Política de Privacidad
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* copy-right area end */}
    </footer>
  );
}

export default Footer;
