import bg_shape from "../../assets/images/others/team-bg-shape.png";
import shape from "../../assets/images/others/team-bg-shape-2.png";
import regidores from "../../assets/images/muni/muni-regidores.jpg";
import alcalde from "../../assets/images/muni/muni-alcalde.png";
import { Helmet } from "react-helmet";
import PageBanner from "../../components/body/PageBanner";
import { APP_NAME } from "../../utils/appName";

function About() {
  return (
    <>
      <Helmet>
        <title>Municipalidad - {APP_NAME}</title>
      </Helmet>
      <PageBanner title="Municipalidad" />
      <div className="tp-team-details-area pt-80 pb-50">
        <div className="container">
          <div className="row">
            <div className="mb-30 text-center">
              <span className="tp-section-subtitle-2">
                Conoce a nuestras autoridades
              </span>
              <h4 className="tp-section-title text-decoration-underline">
                Nuestra Municipalidad Distrital
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="tp-team-details-text-box mb-60">
                <h4 className="tp-inner-title">Visión de Desarrollo</h4>
                <p>
                  El distrito de <b>La Libertad de Pallán</b> aspira a que todos
                  los ciudadanos tengan iguales oportunidades para desarrollar
                  su máximo potencial como seres humanos y alcanzar una mejor
                  calidad de vida, mediante un gobierno local moderno,
                  eficiente, transparente, participativo y ético a su servicio.
                  Con una economía local dinámica, diversificada, utilizando una
                  tecnología adecuada y equilibrada, con empleo y buena
                  productividad en el trabajo, reduciendo la pobreza y pobreza
                  extrema. Aprovechando los recursos naturales de forma
                  sostenible y manteniendo un medio ambiente saludable.
                </p>
              </div>
            </div>
          </div>
          <div className="tp-team-details-bg mb-80 p-relative">
            <div className="tp-team-details-bg-shape d-none d-md-block">
              <img src={bg_shape} alt="bg shape" />
            </div>
            <div className="row align-items-end">
              <div className="col-xl-5 col-lg-5">
                <div className="tp-team-details-thumb text-center text-md-start p-relative">
                  <img src={alcalde} alt="alcalde" />
                  <div className="tp-team-details-thumb-shape d-none d-md-block">
                    <img src={shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7">
                <div className="tp-team-details-info-box">
                  <div className="tp-team-details-title-box">
                    <h4 className="tp-team-details-title">
                      Profesor Manuel Fabián Ballejos Mejia
                    </h4>
                    <span>Alcalde Distrital</span>
                    <p>
                      Conoce a nuestro alcalde, quien es el representante legal
                      de la Municipalidad Distrital de La Libertad de Pallán,
                      quien se encarga de proveer y controlar la prestación de
                      los servicios públicos básicos requeridos para el
                      bienestar de los vecinos y el desarrollo local.
                    </p>
                  </div>
                  <div className="tp-team-details-personal-info-wrap">
                    <div className="tp-team-details-personal-info">
                      <span>
                        <b>Gestión:</b>2023 - 2026
                      </span>
                    </div>
                    {/* <div className="tp-team-details-personal-info">
                      <span>
                        <b>Email Address:</b>
                        <a href="mailto:visaconhelp@gmail.com">
                          visaconhelp@gmail.com
                        </a>
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="tp-team-details-text-box mb-60">
                <h4 className="tp-inner-title">Regidores</h4>
                <p>
                  Nuestros regidores son los representantes de los ciudadanos,
                  quienes asumen funciones normativas y de fiscalización de la
                  gestión municipal, así como también para la presentación de
                  iniciativas y proyectos de mejora de la gestión municipal.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="dp-list-box d-flex align-items-start">
                <div className="dp-list-wrap col-xl-6 mb-30">
                  <div className="tp-mayor-feature-list">
                    <ul>
                      <li>
                        <i className="fa-sharp fa-light fa-badge-check" />
                        <span>
                          <b>Regidor 1:</b> Juan Carlos Rodríguez Cruz
                        </span>
                      </li>
                      <li>
                        <i className="fa-sharp fa-light fa-badge-check" />
                        <span>
                          <b>Regidor 2:</b> Shenifer Dianeli Lobato García
                        </span>
                      </li>
                      <li>
                        <i className="fa-sharp fa-light fa-badge-check" />
                        <span>
                          <b>Regidor 3:</b> Edilfonzo Acuña Saldaña
                        </span>
                      </li>
                      <li>
                        <i className="fa-sharp fa-light fa-badge-check" />
                        <span>
                          <b>Regidor 4:</b> Daniza Campos Cruz
                        </span>
                      </li>
                      <li>
                        <i className="fa-sharp fa-light fa-badge-check" />
                        <span>
                          <b>Regidor 5:</b> Segundo Reynaldo Leyva Cerdán
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="dp-list-thumb col-xl-6">
                  <img src={regidores} alt="regidores" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
