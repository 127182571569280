import "../assets/css/animate.css";
import "../assets/css/bootstrap.css";
import "../assets/css/css2.css";
import "../assets/css/custom-animation.css";
import "../assets/css/flaticon_statex.css";
import "../assets/css/font-awesome-pro.css";
import "../assets/css/main.css";
// import "../assets/css/slick.css";
import "../assets/css/spacing.css";

import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';

import Loader from "../components/body/Loader";
import BackToTop from "../components/body/BackToTop";
import NavBar from "../components/navbar/NavBar";
import Footer from "../components/footer/Footer";

function MainLayout({ children }) {
  return (
    <>
      <Loader />
      <BackToTop />
      <NavBar />
      <div className="main-layout-children">{children}</div>
      <Footer />
    </>
  );
}

export default MainLayout;
