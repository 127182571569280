import { Link } from "react-router-dom";
import { useState, useRef } from "react";
import toast from "react-hot-toast";
import FileInput from "../../components/form/FileInput";
import { responseEmail } from "../../utils/emailSender";

function Form({ title, subtitle, next_link }) {
  // Administrar errores en campos de formulario
  const handleError = (event, index) => {
    const isValid = event.target.validity.valid;

    if (!isValid) {
      error(index);
    } else {
      correct(index);
    }
  };
  const error = (index) => {
    setErrorStates({
      ...errorStates,
      [index]: true,
    });
  };
  const correct = (index) => {
    setErrorStates({
      ...errorStates,
      [index]: false,
    });
  };
  const [errorStates, setErrorStates] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
  });

  const formRef = useRef();
  const [dni, setDni] = useState("");
  const [errorDni, setErrorDni] = useState(false);

  const handleDniChange = (e) => {
    const inputValue = e.target.value;

    // Expresión regular para validar que el DNI sea un número de 8 dígitos
    const regexDni = /^[0-9]{8}$/;

    if (regexDni.test(inputValue)) {
      setDni(inputValue);
      setErrorDni(false);
    } else {
      setErrorDni(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    // Validar otros campos si es necesario
    if (errorDni) {
      toast.error("Ingresa un DNI válido.");
      document.getElementsByName("DNI")[0].focus();
      return;
    }

    // Enviar datos del formulario utilizando el formulario predeterminado
    form.submit();
    toast.success("Tu solicitud ha sido enviada.");

    responseEmail(e, formRef);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="mb-30 text-center">
          <span className="tp-section-subtitle-2">{subtitle}</span>
          <h4 className="tp-section-title text-decoration-underline">
            {title}
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="tp-team-details-text-box mb-60">
            <p>
              Con la finalidad de brindarte más facilidades para la presentación
              de tus documentos, le informamos que puede hacerlo a través de
              nuestra plataforma, en este horario:
            </p>
            <p>
              <ul
                style={{
                  paddingLeft: "20px",
                }}
              >
                <li>
                  <b>De lunes a viernes:</b> de 8:00 a.m. a 17:30 p.m.
                </li>
                <li>
                  La documentación ingresada después de este horario o en días
                  no laborables, se recibirá el día hábil siguiente.
                </li>
                <li>
                  En caso que la documentación presentada supere los 5 MB, se
                  sugiere colocar un enlace de descarga liberado en su
                  solicitud.
                </li>
              </ul>
            </p>
            <p>Para más información, puede comunicarse con nosotros:</p>
            <p>
              <ul
                style={{
                  paddingLeft: "20px",
                }}
              >
                <li>
                  <b>Correo: </b>munipallan2023@gmail.com
                </li>
                <li>
                  <b>Dirección: </b>Jr. La Purísima N° 230 - Plaza de Armas de
                  La Libertad de Pallán
                </li>
              </ul>
            </p>
            <p>*De acuerdo al artículo 46.3 del DS N° 029-2021-PCM</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="tp-checkout-bill-area">
            <div className="tp-checkout-bill-form">
              <form
                ref={formRef}
                action="https://formsubmit.co/munipallan2023@gmail.com"
                method="POST"
                encType="multipart/form-data"
                onSubmit={handleSubmit}
                onError={() => {
                  toast.error("Error al enviar el mensaje.");
                }}
                autoComplete="on"
              >
                <input type="hidden" name="_subject" value={title}></input>
                <input
                  type="hidden"
                  name="_cc"
                  value="jfernandezh19_2@unc.edu.pe"
                ></input>
                <input type="hidden" name="_template" value="table"></input>
                <input type="hidden" name="_captcha" value="false"></input>
                <input
                  type="text"
                  name="_honey"
                  style={{ display: "none" }}
                ></input>
                <input type="hidden" name="_next" value={next_link}></input>
                <div className="tp-checkout-bill-inner">
                  <div className="row pb-20">
                    <h3 className="tp-checkout-bill-title">
                      Información del solicitante
                    </h3>
                    {/* <div className="col-md-12">
                      <div className="tp-checkout-input">
                        <label>
                          Persona Natural o Jurídica{" "}
                          <span className="input-error">*</span>
                        </label>
                        <div className="tp-radio-option-wrapper">
                          <div className="tp-radio-option">
                            <input
                              id="natural_person"
                              name="person"
                              type="radio"
                              checked
                            />
                            <label htmlFor="natural_person">
                              Persona Natural
                            </label>
                          </div>
                          <div className="tp-radio-option">
                            <input
                              id="legal_person"
                              name="person"
                              type="radio"
                            />
                            <label htmlFor="legal_person">
                              Persona Jurídica
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="tp-checkout-input">
                        <label>
                          Nombre <span className="input-error">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Nombre"
                          name="Nombre"
                          maxLength={100}
                          required
                          className={`${errorStates[1] ? "error" : ""}`}
                          onInvalid={() => {
                            error(1);
                          }}
                          onInput={() => {
                            correct(1);
                          }}
                          onBlur={(e) => handleError(e, 1)}
                        />
                        {errorStates[1] && (
                          <span className="input-error">Campo requerido.</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="tp-checkout-input">
                        <label>
                          Apellidos <span className="input-error">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Apellidos"
                          name="Apellidos"
                          maxLength={100}
                          required
                          className={`${errorStates[2] ? "error" : ""}`}
                          onInvalid={() => {
                            error(2);
                          }}
                          onInput={() => {
                            correct(2);
                          }}
                          onBlur={(e) => handleError(e, 2)}
                        />
                        {errorStates[2] && (
                          <span className="input-error">Campo requerido.</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="tp-checkout-input">
                        <label>
                          DNI <span className="input-error">*</span>
                        </label>
                        <input
                          type="number"
                          placeholder="DNI"
                          name="DNI"
                          maxLength={8}
                          required
                          value={dni}
                          onChange={(e) => {
                            setDni(e.target.value);
                            handleDniChange(e);
                          }}
                          className={`${errorStates[0] ? "error" : ""}`}
                          onInvalid={() => {
                            error(0);
                          }}
                          onInput={() => {
                            correct(0);
                          }}
                          onBlur={(e) => handleError(e, 0)}
                          onKeyDown={(e) => {
                            if (
                              e.key === "Tab" ||
                              e.key === "Enter" ||
                              e.key === "Backspace"
                            ) {
                              return;
                            }
                            // limite de 8 caracteres
                            if (e.target.value.length >= 8) {
                              e.preventDefault();
                            }
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {errorStates[0] && (
                          <span className="input-error">Campo requerido.</span>
                        )}
                        {errorDni && (
                          <>
                            <br />
                            <span className="input-error">
                              Ingresa un DNI válido.
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="tp-checkout-input">
                        <label>
                          Teléfono <span className="input-error">*</span>
                        </label>
                        <input
                          type="tel"
                          placeholder="Teléfono"
                          name="Teléfono"
                          maxLength={30}
                          required
                          className={`${errorStates[4] ? "error" : ""}`}
                          onInvalid={() => {
                            error(4);
                          }}
                          onInput={() => {
                            correct(4);
                          }}
                          onBlur={(e) => handleError(e, 4)}
                        />
                        {errorStates[4] && (
                          <span className="input-error">Campo requerido.</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="tp-checkout-input">
                        <label>
                          Correo electrónico{" "}
                          <span className="input-error">*</span>
                        </label>
                        <input
                          type="email"
                          placeholder="Correo electrónico"
                          name="Correo"
                          maxLength={100}
                          required
                          className={`${errorStates[3] ? "error" : ""}`}
                          onInvalid={() => {
                            error(3);
                          }}
                          onInput={() => {
                            correct(3);
                          }}
                          onBlur={(e) => handleError(e, 3)}
                        />
                        {errorStates[3] && (
                          <span className="input-error">Campo requerido.</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr className="pb-30" />
                  <div className="row pb-20">
                    <h3 className="tp-checkout-bill-title">
                      Asunto y descripción del trámite o solicitud
                    </h3>
                    <div className="col-md-12">
                      <div className="tp-checkout-input">
                        <label>
                          Asunto <span className="input-error">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Asunto"
                          name="Asunto"
                          maxLength={150}
                          required
                          className={`${errorStates[5] ? "error" : ""}`}
                          onInvalid={() => {
                            error(5);
                          }}
                          onInput={() => {
                            correct(5);
                          }}
                          onBlur={(e) => handleError(e, 5)}
                        />
                        {errorStates[5] && (
                          <span className="input-error">Campo requerido.</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="tp-checkout-input">
                        <label>
                          Descripción <span className="input-error">*</span>
                        </label>
                        <textarea
                          placeholder="Descripción del trámite o solicitud a enviar"
                          name="Descripción"
                          maxLength={600}
                          required
                          className={`${errorStates[6] ? "error" : ""}`}
                          onInvalid={() => {
                            error(6);
                          }}
                          onInput={() => {
                            correct(6);
                          }}
                          onBlur={(e) => handleError(e, 6)}
                        />
                        {errorStates[6] && (
                          <span className="input-error">Campo requerido.</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr className="pb-30" />
                  <div className="row">
                    <h3 className="tp-checkout-bill-title">
                      Documentos de sustento (opcional)
                    </h3>
                    <div className="col-md-12">
                      <div className="tp-checkout-input">
                        <label>
                          Url de descarga de documentos (Si pesa más de 5 MB)
                        </label>
                        <input
                          type="url"
                          placeholder="Url"
                          name="Url"
                          maxLength={200}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="tp-checkout-input">
                        <label>
                          Adjunta los documentos que sustenten tu solicitud
                        </label>
                        <br />
                        <ul
                          style={{
                            paddingLeft: "20px",
                          }}
                        >
                          <li>
                            <label>Peso máximo: 5 MB en total</label>
                          </li>
                          <li>
                            <label>
                              Solo se aceptan los formatos: .pdf, .doc y .docx
                            </label>
                          </li>
                        </ul>
                        <FileInput />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="tp-checkout-option-wrapper">
                        <div className="tp-checkout-option">
                          <input
                            id="create_free_account"
                            type="checkbox"
                            required
                            onInvalid={() => {
                              error(7);
                            }}
                            onChange={(e) => handleError(e, 7)}
                          />
                          <label htmlFor="create_free_account">
                            Acepto la{" "}
                            <Link to="/politica-de-privacidad" target="_blank">
                              Política de Privacidad
                            </Link>
                            .
                          </label>
                        </div>
                        {errorStates[7] && (
                          <span className="input-error">Campo requerido.</span>
                        )}
                        <div className="pt-20"></div>
                        <div className="tp-checkout-option">
                          <input
                            id="ship_to_diff_address"
                            type="checkbox"
                            required
                            onInvalid={() => {
                              error(8);
                            }}
                            onChange={(e) => handleError(e, 8)}
                          />
                          <label htmlFor="ship_to_diff_address">
                            Declaro bajo juramento que los datos ingresados en
                            este formulario son verdaderos y están sujetos a lo
                            establecido en los articulos 51 y 67 del{" "}
                            <a
                              href="https://cdn.www.gob.pe/uploads/document/file/320709/DS_004-2019-JUS.pdf"
                              target="_blank"
                              rel="noopenner noreferrer"
                            >
                              TUO de la Ley N° 27444
                            </a>
                            .
                          </label>
                        </div>
                        {errorStates[8] && (
                          <span className="input-error">Campo requerido.</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="tp-checkout-btn-wrapper">
                        <button
                          type="submit"
                          className="tp-btn text-center w-100"
                        >
                          Enviar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Form;
