import post_07_06_2024 from "../assets/images/posts/post_07_06_2024.jpeg";
import post_06_06_2024 from "../assets/images/posts/post_06_06_2024.png";
import post_30_05_2024 from "../assets/images/posts/post_30_05_2024.jpeg";
import post_21_05_2024_2 from "../assets/images/posts/post_21_05_2024_2.png";
import post_21_05_2024_1 from "../assets/images/posts/post_21_05_2024_1.png";
import post_12_05_2024 from "../assets/images/posts/post_12_05_2024.png";
import post_09_05_2024 from "../assets/images/posts/post_09_05_2024.jpeg";
import post_01_05_2024 from "../assets/images/posts/post_01_05_2024.png";
import post_29_04_2024 from "../assets/images/posts/post_29_04_2024.jpg";
import post_22_10_2024 from "../assets/images/posts/post_22_10_2024.png";
import post_19_11_2024 from "../assets/images/posts/post_19_11_2024.jpeg";

export const postsList = [
    {
        message: "Convocatoria para Yachachiq en La Libertad de Pallan, Cajamarca. Postulaciones del 18 al 29 de noviembre vía FONCODES.",
        full_picture: post_19_11_2024,
        created_time: "2024-11-19",
        btn_label: "Compartir",
        type: "Share",
    },
    {
        message: "Lamentamos el sensible fallecimiento de Guitler Ronal Mendoza Micha, acompañamos a su familia en este difícil momento y pedimos a Dios que les de fortaleza y resignación.",
        full_picture: post_22_10_2024,
        created_time: "2024-10-22",
        btn_label: "Compartir",
        type: "Share",
    },
    {
        message: "Entrega de alimentos del convenio entre la Municipalidad de La Libertad de Pallán y el Programa Qali Warma a instituciones educativas de nuestro distrito.",
        full_picture: "https://drive.google.com/thumbnail?id=19-Ob93pvfcPXchbDVqYXWQF29AZu-ldN&sz=w1000",
        created_time: "2024-10-22",
        permalink_url: "https://drive.google.com/drive/folders/1LxpGsFTeSCloGnNv36QFE8QTfdvR0V8G?usp=sharing",
        btn_label: "Ver más",
        type: "Article",
    },
    {
        message: "Firma de convenio entre la Municipalidad de La Libertad de Pallán y de Huasmín.",
        full_picture: "https://drive.google.com/thumbnail?id=1oUBfagRz3j1c3Aq6DMm4RMJPaeEyRUsE&sz=w1000",
        created_time: "2024-10-21",
        permalink_url: "https://drive.google.com/drive/folders/1J_Yg6ChHmAZX3Lb6rQKaIAZEeSseWN2I?usp=sharing",
        btn_label: "Ver más",
        type: "Article",
    },
    {
        message: "Convocatoria para la Contratación Administrativa de Servicios - CAS del sector público de la Municipalidad Distrital de la Libertad de Pallán.",
        created_time: "2024-08-14",
        permalink_url: "https://drive.google.com/drive/folders/1PZnEcClLkGBVMbJ85OaOXYy3oJFql2Ct?usp=sharing",
        btn_label: "Ver más",
        type: "Article",
    },
    {
        message: "Construcción de 2 aulas para el nivel inicial en la comunidad Unión del Norte.",
        full_picture: "https://drive.google.com/thumbnail?id=1X2OsxzPaEgLZ7nD3qLG2A1zvM2PMZyO7&sz=w1000",
        created_time: "2024-07-11",
        permalink_url: "https://drive.google.com/drive/folders/1V9SWLWBY_K2cOuee-MoDYtavbhKL3VMG?usp=sharing",
        btn_label: "Ver más",
        type: "Article",
    },
    {
        message: "Marcha por el Día Nacional de la Bandera, en nuestro distrito de La Libertad de Pallán.",
        full_picture: post_07_06_2024,
        created_time: "2024-06-07",
        permalink_url: "https://drive.google.com/drive/folders/16gH6VTQAiwF_004EZkkeSag7eOK9VUR4?usp=sharing",
        btn_label: "Ver más",
        type: "Article",
    },
    {
        message: "Programa para la Fiesta de San Juan Bautista en La Libertad de Pallán en el año 2024.",
        full_picture: "https://www.munilalibertaddepallan.gob.pe/static/media/photo-5.4d8979674f0f61665cf0.jpg",
        created_time: "2024-06-06",
        permalink_url: "https://drive.google.com/file/d/1Gm-zeEgs0NGhP979zlzooqJEXqgkKcsV/view?usp=drive_link",
        btn_label: "Ver más",
        type: "Article",
    },
    {
        message: "Invitación a la competencia de Reinas de Belleza de La Libertad de Pallán, el miércoles 19 de junio.",
        full_picture: post_06_06_2024,
        created_time: "2024-06-06",
        permalink_url: "https://www.youtube.com/watch?v=8u0eb1vZi0k",
        btn_label: "Ver video",
        type: "Video",
    },
    {
        message: "Entrega de alimentos por parte de la municipalidad a instituciones educativas en los caseríos de Nuevo Horizonte y Paucapampa",
        full_picture: post_30_05_2024,
        created_time: "2024-05-30",
        permalink_url: "https://drive.google.com/drive/folders/1dykCzeXe1mV3PxTxivG7c6OWdrfVhgTX?usp=sharing",
        btn_label: "Ver más",
        type: "Article",
    },
    {
        message: "Visita del congresista Américo Gonza a nuestro distrito y su reunión con nuestras autoridades locales.",
        full_picture: post_21_05_2024_1,
        created_time: "2024-05-21",
        permalink_url: "https://www.facebook.com/share/v/Y6qx8xBsLM2LU59J/?mibextid=CmRSLE",
    },
    {
        message: "Visita del congresista Américo Gonza al centro poblado de Callacat Bajo en el distrito de La Libertad de Pallán.",
        full_picture: post_21_05_2024_2,
        created_time: "2024-05-21",
        permalink_url: "https://www.facebook.com/share/v/YysSQvLPNemTVion/?mibextid=U8WOFx",
    },
    {
        message: "¡Feliz Día de la Madre! - A todas las madres de nuestro distrito, gracias por su amor y dedicación.",
        full_picture: post_12_05_2024,
        created_time: "2024-05-12",
        permalink_url: "https://www.youtube.com/watch?v=Qd3AAV8HZSc",
        btn_label: "Ver video",
        type: "Video",
    },
    {
        message: "Firma de convenio entre la Municipalidad de Celendín y la Municipalidad de La Libertad de Pallán.",
        full_picture: post_09_05_2024,
        created_time: "2024-05-09",
        permalink_url: "https://drive.google.com/drive/folders/1ondO4SFarbeTTtx6AoMjalrgeoNM6pSS?usp=sharing",
        btn_label: "Ver más",
        type: "Article",
    },
    {
        message: "¡Feliz Día del Trabajo! - ¡Gracias a todos los trabajadores por su incansable y noble labor!",
        full_picture: post_01_05_2024,
        created_time: "2024-05-01",
        btn_label: "Compartir",
        type: "Share",
    },
    {
        message: "Presupuesto Participativo 2025 - Inscríbete en nuestra mesa de partes virtual y participa.",
        full_picture: post_29_04_2024,
        created_time: "2024-04-29",
        permalink_url: "https://www.youtube.com/watch?v=4WOmTcEbX58",
        btn_label: "Ver video",
        type: "Video",
    },
    {
        message: "Transferencia de partidas a favor de gobiernos locales para la ejecución de proyectos de inversión pública.",
        created_time: "2024-04-29",
        permalink_url: "/municipalidad/instrumentos-de-gestion/transferencia-de-partidas",
        btn_label: "Ver más",
        target: "_self",
        type: "Article",
    },
];
