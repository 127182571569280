import { Skeleton } from "primereact/skeleton";

function SkeletonNews() {
  return (
    <>
      <div className="row">
        <div className="tp-event-3-item col-xl-4 col-lg-4 col-md-6 mb-30">
          <div className="tp-event-3-thumb fix">
            <div className="tp-event-3-thumb fix">
              <Skeleton width="100%" height="315.16px"></Skeleton>
            </div>
          </div>
          <div className="tp-event-3-content-wrap">
            <div className="tp-event-3-content">
              <div className="tp-event-3-meta">
                <Skeleton height="1.5rem" width="5rem"></Skeleton>
                <Skeleton height="1.5rem" width="5rem"></Skeleton>
              </div>
              <Skeleton height="5rem"></Skeleton>
            </div>
            <Skeleton height="1.5rem" className="mt-10"></Skeleton>
          </div>
        </div>
        <div className="tp-event-3-item col-xl-4 col-lg-4 col-md-6 mb-30 d-md-block d-none">
          <div className="tp-event-3-thumb fix">
            <div className="tp-event-3-thumb fix">
              <Skeleton width="100%" height="315.16px"></Skeleton>
            </div>
          </div>
          <div className="tp-event-3-content-wrap">
            <div className="tp-event-3-content">
              <div className="tp-event-3-meta">
                <Skeleton height="1.5rem" width="5rem"></Skeleton>
                <Skeleton height="1.5rem" width="5rem"></Skeleton>
              </div>
              <Skeleton height="5rem"></Skeleton>
            </div>
            <Skeleton height="1.5rem" className="mt-10"></Skeleton>
          </div>
        </div>
        <div className="tp-event-3-item col-xl-4 col-lg-4 col-md-6 mb-30 d-md-block d-none">
          <div className="tp-event-3-thumb fix">
            <div className="tp-event-3-thumb fix">
              <Skeleton width="100%" height="315.16px"></Skeleton>
            </div>
          </div>
          <div className="tp-event-3-content-wrap">
            <div className="tp-event-3-content">
              <div className="tp-event-3-meta">
                <Skeleton height="1.5rem" width="5rem"></Skeleton>
                <Skeleton height="1.5rem" width="5rem"></Skeleton>
              </div>
              <Skeleton height="5rem"></Skeleton>
            </div>
            <Skeleton height="1.5rem" className="mt-10"></Skeleton>
          </div>
        </div>
      </div>
    </>
  );
}

export default SkeletonNews;
