import { Helmet } from "react-helmet";
import PageBanner from "../../components/body/PageBanner";
import { APP_NAME } from "../../utils/appName";
import Form from "../../components/form/Form";

function PartsTable() {
  return (
    <>
      <Helmet>
        <title>Mesa de Partes Virtual - {APP_NAME}</title>
      </Helmet>
      <PageBanner
        title="Servicios"
        subtitle="Mesa de Partes Virtual"
        parent_link="/servicios"
      />
      <section className="tp-checkout-area pb-50 pt-80">
        <Form
          title="Mesa de Partes Virtual"
          subtitle="¿Requieres presentar un documento a la municipalidad?"
          next_link="https://www.munilalibertaddepallan.gob.pe/servicios/mesa-de-partes-virtual"
        />
      </section>
    </>
  );
}

export default PartsTable;
