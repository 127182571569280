import { Helmet } from "react-helmet";
import item_shape from "../../assets/images/others/sv-item-shape.png";
import { APP_NAME } from "../../utils/appName";
import PageBanner from "../../components/body/PageBanner";

const data = [
  {
    icon: "flaticon-approved",
    title: "Misión",
    desc: "Nuestra misión es brindar servicios de calidad a la población, con eficiencia, eficacia y transparencia, promoviendo el desarrollo integral del distrito de La Libertad de Pallán, en un marco de respeto a la diversidad cultural y de género, con la participación activa de la población, para mejorar su calidad de vida.",
  },
  {
    icon: "flaticon-car-insurance",
    title: "Visión",
    desc: "Nuestra visión es ser una institución moderna, eficiente, eficaz y transparente, que brinde servicios de calidad a la población, con personal capacitado y comprometido con el desarrollo del distrito de La Libertad de Pallán, en un marco de respeto a la diversidad cultural, con la participación de la población.",
  },
  {
    icon: "flaticon-industrial-park",
    title: "Filosofía",
    desc: "Creemos en la capacidad de las personas para desarrollar sus potencialidades y en la necesidad de que el Estado brinde servicios de calidad a la población, con eficiencia, eficacia y transparencia, promoviendo el desarrollo integral del distrito de La Libertad de Pallán, en un marco de respeto a la diversidad cultural y de género.",
  },
  {
    icon: "flaticon-scroll",
    title: "Valores",
    desc: "Los valores que nos identifican son: Honestidad, Transparencia, Responsabilidad, Confianza, Compromiso, Respeto, Solidaridad, Equidad, Justicia, Tolerancia, Participación, Trabajo en equipo, Vocación de servicio, Calidad, Eficiencia, Eficacia, Innovación, Creatividad, Puntualidad, Orden, Disciplina, Lealtad, entre otros.",
  },
];

function MissionAndVision() {
  return (
    <>
      <Helmet>
        <title>Misión y Visión - {APP_NAME}</title>
      </Helmet>
      <PageBanner
        title="Municipalidad"
        subtitle="Misión y Visión"
        parent_link="/municipalidad"
      />
      <div className="tp-service-area p-relative pt-80 pb-50">
        <div className="container custom-container">
          <div className="row">
            <div className="col-xl-12">
              <div className="tp-service-title-box text-center mb-70">
                <span className="tp-section-subtitle">
                  Conoce como trabajamos
                </span>
                <h4 className="tp-section-title">Nuestra Misión y Visión</h4>
              </div>
            </div>
          </div>
          <div className="row">
            {data.map((item, index) => (
              <div
                key={index}
                className="col-md-6 mb-30 wow tpfadeUp"
                style={{
                  visibility: "visible",
                  animationDuration: "0.9s",
                  animationDelay: "0.3s",
                  animationName: "tpfadeUp",
                }}
              >
                <div className="tp-service-item p-relative">
                  <div className="tp-service-shape">
                    <img src={item_shape} alt="item thumb" />
                  </div>
                  <div className="tp-service-icon">
                    <i className={item.icon} />
                  </div>
                  <div className="tp-service-content">
                    <h4 className="tp-service-title-sm">
                      <span>{item.title}</span>
                    </h4>
                    <p>{item.desc}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default MissionAndVision;
