function Map() {
  return (
    <>
      <div className="tp-brand-area pb-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="tp-brand-title-box mb-60 text-center">
                <i className="flaticon-spark" />
                <span className="tp-section-subtitle-2">
                  Mapa Interactivo del Distrito
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tp-map">
        <iframe
          title="Mapa de la Libertad de Pallán"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2471.3277445190656!2d-78.28183365531532!3d-6.723481277039951!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91b3c7656529d3ab%3A0x5ee5e36240f4778d!2sLa%20Libertad%20de%20Pall%C3%A1n!5e1!3m2!1ses!2spe!4v1701296787278!5m2!1ses!2spe"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    </>
  );
}

export default Map;
