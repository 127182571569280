import { Link } from "react-router-dom";

function Documents({ title, items }) {
  return (
    <div className="tp-service-3-area pt-80 pb-50">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="tp-service-title-box text-center mb-70">
              <span className="tp-section-subtitle">
                Documentos para el trabajo municipal
              </span>
              <h4 className="tp-section-title">{title}</h4>
            </div>
          </div>
        </div>
        <div className="row counter-row">
          {items.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              target={item.target}
              className="col-xl-3 col-lg-6 col-md-6 mb-30 d-flex"
            >
              <div className="tp-service-3-item p-relative z-index h-100 d-flex flex-column">
                <div className="tp-service-3-counter-text" />
                <div className="tp-service-3-icon p-relative">
                  <i className="fal fa-file-pdf" />
                  <span />
                </div>
                <div className="tp-service-3-content">
                  <h4 className="tp-service-3-title-sm">
                    <Link to={item.path} target={item.target}>
                      {item.title}
                    </Link>
                  </h4>
                </div>
                <div className="tp-service-3-btn mt-auto">
                  <Link
                    className="tp-btn-sv"
                    to={item.path}
                    target={item.target}
                  >
                    Ver más
                    <i className="fa-light fa-arrow-right-long" />
                  </Link>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Documents;
