import { Link } from "react-router-dom";

import { Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import slider_1 from "../../assets/images/slider/slider-1.jpg";
import slider_2 from "../../assets/images/slider/slider-2.jpg";
import slider_3 from "../../assets/images/slider/slider-3.jpg";
// import slider_4 from "../../assets/images/slider/slider-4.jpg";

const sliderImages = [slider_1, slider_2, slider_3];

function Slider() {
  return (
    <Swiper
      modules={[Navigation, Autoplay]}
      slidesPerView={1}
      navigation={{
        prevEl: ".slider-prev",
        nextEl: ".slider-next",
      }}
      autoplay={{
        delay: 5000,
        disableOnInteraction: true,
      }}
      speed={1000}
      loop={true}
      className="tp-slider-wrapper"
    >
      <div className="tp-slider-arrow-box">
        <button className="slider-prev">
          <i className="fa-regular fa-arrow-left" />
        </button>
        <button className="slider-next">
          <i className="fa-regular fa-arrow-right" />
        </button>
      </div>
      {sliderImages.map((image, index) => (
        <SwiperSlide key={index}>
          <div
            className="tp-slider-bg d-flex justify-content-center align-items-center p-relative fix"
            style={{ backgroundImage: `url(${image})` }}
          >
            {/* <img
              className="tp-slider-img"
              style={{ backgroundImage: `url(${image})` }}
              alt="slide"
            /> */}
            <div className="container">
              <div className="row">
                <div className="col-xl-9">
                  <div className="tp-slider-content-wrap p-relative z-index-2">
                    <div className="tp-slider-title-box p-relative">
                      <span className="tp-slider-subtitle">
                        VISITA ESTE HERMOSO LUGAR
                      </span>
                      <h4 className="tp-slider-title">
                        LA LIBERTAD <br /> DE PALLÁN
                      </h4>
                      <p>
                        ¿Te gustaría descubrir un lugar lleno de historia,
                        cultura y belleza natural? Entonces La Libertad de
                        Pallán es el lugar para ti.
                      </p>
                    </div>
                    <div className="tp-slider-video-box d-flex align-items-center">
                      <div className="tp-slider-btn">
                        <Link className="tp-btn-xl" to="/distrito">
                          Descubre el distrito
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Slider;
