import React, { useState, useEffect } from "react";
import Posts from "./Posts";
import { Helmet } from "react-helmet";
import axios from "axios";
import SkeletonNews from "./SkeletonNews";
import toast from "react-hot-toast";
import PageBanner from "../../components/body/PageBanner";
import { Link, useLocation } from "react-router-dom";
import { scrollToTop } from "../../utils/scrollUtils";
import { APP_NAME } from "../../utils/appName";
// import { newsList } from "../../utils/getNews";
import { postsList } from "../../utils/getPosts";

function News() {
  const REACT_APP_FACEBOOK_POSTS = process.env.REACT_APP_FACEBOOK_POSTS;

  // Obtener los posts de Facebook
  const [responseData, setResponseData] = useState(postsList);

  // Notificación de error
  const notify = () =>
    toast.error("No se pudo cargar las noticias. Reinicie la página.");

  // useEffect(() => {
  //   const access = REACT_APP_FACEBOOK_POSTS;
  //   const fields = "posts";
  //   const subfields = "message,full_picture,created_time,permalink_url";
  //   let isMounted = true; // Bandera para verificar si el componente está montado

  //   async function fetchFacebookPosts() {
  //     const apiUrl = `https://graph.facebook.com/v19.0/me?fields=${fields}{${subfields}}&access_token=${access}`;

  //     const { data: fb_data } = await axios
  //       .get(apiUrl)
  //       .then((res) => res)
  //       .catch((err) => {
  //         if (isMounted) {
  //           console.error("Error: " + err);
  //           notify();
  //         }
  //       });

  //     let { posts } = fb_data;

  //     if (isMounted) {
  //       setResponseData((prevState) => [...prevState, ...posts.data]);
  //     }
  //   }

  //   if (isMounted) {
  //     fetchFacebookPosts();
  //   }

  //   // Cuando el componente se desmonte, actualiza la bandera
  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);

  const location = useLocation();

  return (
    <>
      {location.pathname === "/noticias" && (
        <>
          <Helmet>
            <title>Noticias - {APP_NAME}</title>
          </Helmet>
          <PageBanner title="Noticias" />
        </>
      )}

      <div id="blog-one-page" className="tp-blog-area pt-80 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="tp-blog-section-title text-center mb-55">
                <span className="tp-section-subtitle-2">
                  Lo último de nuestro distrito
                </span>
                <h4 className="tp-section-title text-decoration-underline">
                  Noticias de Nuestra Municipalidad
                </h4>
                {/* <br />
                <h5>
                  NOTA: Esta página está en fase de pruebas. Se mostrarán las
                  últimas publicaciones de una página de Facebook alternativa.
                </h5> */}
              </div>
            </div>
          </div>
          {responseData ? null : <SkeletonNews />}
          <Posts
            data={
              responseData
                ? location.pathname === "/noticias"
                  ? responseData
                  : responseData.slice(0, 6)
                : []
            }
          />
        </div>
        {location.pathname !== "/noticias" && (
          <Link
            className="tp-btn-xl green-anim"
            to="/noticias"
            onClick={scrollToTop}
          >
            Ver más noticias
          </Link>
        )}
      </div>
    </>
  );
}

export default React.memo(News);
