import background from "../../assets/images/muni/background.jpg";

import { jarallax } from "jarallax";
import React, { useRef, useEffect, useState } from "react";
import ModalVideo from "react-modal-video";
import Gallery from "../../components/muni/Gallery";

function Tourism() {
  // Usando jarallax para el efecto scroll
  const jarallaxContainerRef = useRef(null);

  useEffect(() => {
    if (jarallaxContainerRef.current) {
      jarallax(jarallaxContainerRef.current, {
        speed: 0.2,
        imgWidth: 1366,
        imgHeight: 768,
      });
    }
  }, []);

  // Usando estados del modal para el video
  const [isOpen, setOpen] = useState(false);

  // Función para cerrar el elemento
  const closeElement = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="tp-porfolio-details-area project-details-customize pt-80">
        <div className="container">
          <div className="row">
            <div className="mb-30 text-center">
              <span className="tp-section-subtitle-2">
                Descubre los mejores lugares
              </span>
              <h4 className="tp-section-title text-decoration-underline">
                Atractivos Turísticos
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="evn-text-box mb-30">
                <h4 className="tp-inner-title">Naturaleza y Paisajes</h4>
                <p>
                  La Libertad de Pallán también es famosa por su impresionante
                  belleza natural. Rodeado de montañas majestuosas y valles
                  exuberantes, este destino te ofrece una experiencia única en
                  contacto con la naturaleza. Realiza caminatas y excursiones
                  por los senderos locales para disfrutar de vistas panorámicas
                  y respirar aire fresco. Además, no te olvides de visitar las
                  cascadas y los ríos que adornan el paisaje, perfectos para
                  relajarte y disfrutar de momentos de tranquilidad. Entre los
                  atractivos turísticos más destacados se encuentran:
                </p>
              </div>
              <div className="evn-list-box mb-30">
                <div className="container">
                  <div className="row">
                    <ul className="col-xl col-lg">
                      <li>
                        <span>
                          <i className="fa-regular fa-check" />
                        </span>
                        <p>Catarata Huamamachay (Saraús)</p>
                      </li>
                      <li>
                        <span>
                          <i className="fa-regular fa-check" />
                        </span>
                        <p>Laguna La Quihuila</p>
                      </li>
                      <li>
                        <span>
                          <i className="fa-regular fa-check" />
                        </span>
                        <p>Cerro El Calvario</p>
                      </li>
                      <li>
                        <span>
                          <i className="fa-regular fa-check" />
                        </span>
                        <p>Bosque de piedras de Paucapampa</p>
                      </li>
                    </ul>
                    <ul className="col-xl col-lg">
                      <li>
                        <span>
                          <i className="fa-regular fa-check" />
                        </span>
                        <p>Centro arqueológico El Molino</p>
                      </li>
                      <li>
                        <span>
                          <i className="fa-regular fa-check" />
                        </span>
                        <p>Miradores naturales</p>
                      </li>
                      <li>
                        <span>
                          <i className="fa-regular fa-check" />
                        </span>
                        <p>Diversidad de flora y fauna</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="evn-text-box mb-30">
                <h4 className="tp-inner-title">Costumbres y Tradiciones</h4>
                <p>
                  Una de las mejores formas de sumergirte en la cultura de La
                  Libertad de Pallán es participar en sus festividades y conocer
                  sus tradiciones. Descubre la alegría y el colorido de las
                  celebraciones locales, donde la música, la danza y los trajes
                  típicos se combinan para crear una experiencia inolvidable.
                  Además, no puedes dejar de probar la deliciosa gastronomía
                  local, que ofrece platos tradicionales preparados con
                  ingredientes frescos y autóctonos de la región. Entre las más
                  destacadas festividades se encuentran:
                </p>
              </div>
              <div className="evn-list-box mb-50">
                <div className="container">
                  <div className="row">
                    <ul className="col-xl col-lg">
                      <li>
                        <span>
                          <i className="fa-regular fa-check" />
                        </span>
                        <p>
                          <strong>24 de junio:</strong> Fiesta patronal en honor
                          a San Juan Bautista
                        </p>
                      </li>
                    </ul>
                    <ul className="col-xl col-lg">
                      <li>
                        <span>
                          <i className="fa-regular fa-check" />
                        </span>
                        <p>
                          <strong>23 de diciembre:</strong> Aniversario de
                          creación del distrito
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tp-brand-area mb-30">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="tp-brand-title-box text-center">
                <i className="flaticon-spark" />
                <span className="tp-section-subtitle-2">Galería de fotos</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Gallery />
      <div className="tp-brand-area mb-30">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="tp-brand-title-box text-center">
                <i className="flaticon-spark" />
                <span className="tp-section-subtitle-2">
                  Conoce de cerca el distrito
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tp-video-area tp-video-space fix p-relative">
        <div
          ref={jarallaxContainerRef}
          className="tp-video-bg jarallax"
          style={{ backgroundImage: `url(${background})` }}
        />
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="tp-video-content text-center">
                <div className="tp-video-content-icon-box">
                  <button
                    className="video-animation-2"
                    onClick={() => setOpen(true)}
                  >
                    <i className="flaticon-play" />
                  </button>
                </div>
                <h6 className="tp-video-content-title">
                  Mira este video para conocer el distrito de La Libertad de
                  Pallán
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="pfQrhOkFceM"
          onClose={closeElement}
        />
        <div
          className={`body-overlay ${isOpen ? "apply" : ""}`}
          onClick={closeElement}
        />
      </React.Fragment>
    </>
  );
}

export default Tourism;
