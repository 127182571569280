import { useEffect } from "react";
import { useRef, useState } from "react";
import toast from "react-hot-toast";

function FileInput() {
  // Administrar archivos subivos en input file
  const [file, setFile] = useState(null);

  const handleFileChange = (fileInfo) => {
    setFile(fileInfo);
  };
  const handleInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileChange(file);
    }
  };

  // Eliminar archivo
  const deleteFile = () => (
    <button
      onClick={(e) => {
        e.preventDefault();
        setFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
      }}
      type="button"
      className="ml-15"
    >
      <i className="fa-solid fa-trash mr-5" />
      Eliminar
    </button>
  );

  // Validar archivo
  const validateFileSize = () => {
    if (file && !validateFileFormat()) {
      toast.error("Formato de archivo no válido.");
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      return;
    }
    if (file && file.size > 0 && file.size <= 5242880) {
      toast.success("Archivo subido correctamente.");
      return;
    }
    if (file && file.size === 0) {
      toast.error("Archivo muy pequeño.");
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      return;
    }
    if (file && file.size > 5242880) {
      toast.error("Archivo muy grande.");
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      return;
    }
  };

  // validar estos formatos ".pdf, .doc, .docx" en el archivo
  const validateFileFormat = () => {
    const validFormats = [".pdf", ".doc", ".docx"];
    const fileName = file ? file.name : "";
    const fileNameArray = fileName.split(".");
    const fileExtension = "." + fileNameArray[fileNameArray.length - 1];
    return validFormats.includes(fileExtension);
  };

  useEffect(() => {
    validateFileSize();
  }, [file]);

  // Mostrar información del archivo

  const showFileInfo = () => {
    const sizeValid = file && file.size > 0 && file.size <= 5242880;
    const fileDetailsClass =
      sizeValid && validateFileFormat() ? "text-success" : "text-danger";
    return (
      <>
        <br />
        <b className={fileDetailsClass}>{getFileDetails()}</b>
        {deleteFile()}
      </>
    );
  };

  const getFileDetails = () =>
    file ? `${file.name} - ${formatFileSize(file.size)}` : "";

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)));
    return Math.round((bytes / Math.pow(k, i)) * 100) / 100 + " " + sizes[i];
  };

  // Administrar click en botón de input file

  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
    fileInputRef.current.value = null;
  };
  return (
    <>
      <input
        type="file"
        name="Archivo"
        accept=".pdf, .doc, .docx"
        className="d-none"
        ref={fileInputRef}
        onChange={handleInputChange}
      />
      <div className="pt-10">
        <button
          className="tp-btn-input text-center border"
          type="button"
          onClick={handleClick}
        >
          <i className="fa-sharp fa-regular fa-file-circle-plus mr-5" />
          <span>Seleccionar archivo</span>
        </button>
        {file && showFileInfo()}
      </div>
    </>
  );
}

export default FileInput;
