import React, { useState } from "react";
import logo from "../../assets/images/muni/logo.png";
import SideBarMenu from "./SideBarMenu";
import { Link, useLocation } from "react-router-dom";
import { scrollToTop } from "../../utils/scrollUtils";
import { menuItems } from "../../utils/menuItems";

function NavBar() {
  const location = useLocation();

  const scrollToTopLink = () => {
    scrollToTop();
    closeSideBar();
  };

  const [showSideBar, setShowSideBar] = useState(false);

  function openSideBar() {
    setShowSideBar(true);
  }

  function closeSideBar() {
    setShowSideBar(false);
  }

  const generateMenuItem = (items, parentPath = "") => {
    return items.map((item, index) => {
      const fullPath = parentPath + item.path;
      const currentPath = location.pathname;

      return (
        <li key={index}>
          <Link
            to={!item.target ? fullPath : item.path}
            onClick={() => {
              if (!item.target) {
                scrollToTopLink();
              }
            }}
            className={
              currentPath === fullPath
                ? "active"
                : item.submenu && currentPath.includes(fullPath)
                ? "active"
                : ""
            }
            target={item.target}
          >
            {item.text}
          </Link>
          {item.submenu && (
            <ul
              className="tp-submenu submenu"
              style={{
                overflowY: "auto",
                maxHeight: "100vh",
              }}
            >
              {generateMenuItem(item.submenu, fullPath)}
            </ul>
          )}
        </li>
      );
    });
  };

  return (
    <>
      <header id="home" className="tp-header-height">
        <div
          id="header-sticky"
          className="tp-header-area tp-header-plr header-sticky"
        >
          <div className="container-fluid">
            <div className="row align-items-center justify-content-between">
              <div className="col-xxl-6 col-xl-4 col-lg-6 col-md-6 col-8">
                <div className="tp-header-logo">
                  <Link
                    to="/"
                    onClick={scrollToTopLink}
                    className="d-flex align-items-center"
                  >
                    <img src={logo} width={50} height={50} alt="logo" />
                    <h3 className="pl-5 mb-0 d-none d-sm-block">
                      La Libertad de Pallán
                    </h3>
                  </Link>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-5 d-none d-xl-block">
                <div className="tp-header-menu">
                  <nav className="tp-main-menu-content">
                    <ul className="tp-onepage-menu d-flex justify-content-end">
                      {generateMenuItem(menuItems)}
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-3 col-lg-6 col-md-6 col-4 d-xl-none">
                <div className="tp-header-right-box">
                  <div className="tp-header-right-action d-flex align-items-center justify-content-end">
                    <div className="tp-header-bar text-end">
                      <button className="tp-menu-bar" onClick={openSideBar}>
                        <i className="fa-light fa-bars-staggered" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <SideBarMenu show={showSideBar} closeSideBar={closeSideBar} />
    </>
  );
}

export default NavBar;
