import { Link } from "react-router-dom";
import { scrollToTop } from "../../utils/scrollUtils";

function Description({
  mainImage,
  span,
  title,
  subtitle,
  description,
  asset_link = null,
}) {
  return (
    <div className="tp-about-2-area pt-80 pb-50">
      <div className="container">
        <div className="row">
          <div className="tp-history-title-box mb-60 text-center">
            <span className="tp-section-subtitle-2">{span}</span>
            <h4 className="tp-section-title text-decoration-underline">
              {title}
            </h4>
          </div>
        </div>
        <div className="row align-items-start">
          <div
            className="col-xl-6 col-lg-6 wow tpfadeLeft"
            style={{
              visibility: "visible",
              animationDuration: "0.9s",
              animationDelay: "0.3s",
              animationName: "tpfadeLeft",
            }}
          >
            <div className="mb-lg-0 mb-40 mr-md-40">
              <div className="d-flex justify-content-center">
                <img src={mainImage} alt="thumb" />
              </div>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 wow tpfadeRight"
            style={{
              visibility: "visible",
              animationDuration: "0.9s",
              animationDelay: "0.5s",
              animationName: "tpfadeRight",
            }}
          >
            <div className="tp-about-2-right-wrap pl-20">
              <div className="tp-about-2-title-box">
                <h3 className="mb-20">{subtitle}</h3>
                <p>{description}</p>
              </div>
              <div className="tp-about-2-tel-wrap">
                <div className="row">
                  <div className="col-md-12">
                    <div className="tp-about-tel-box d-flex align-items-center">
                      <div className="tp-about-tel-icon">
                        <span>
                          <i className="flaticon-bank" />
                        </span>
                      </div>
                      <div className="tp-about-tel-number">
                        <span>Dirección:</span>
                        <p>
                          Jr. La Purísima N° 230 - Plaza de Armas de La Libertad
                          de Pallán
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tp-about-2-text">
                  <p>¿Tienes alguna duda? ¿Quieres saber más sobre nosotros?</p>
                </div>
                <div className="d-md-flex align-items-center justify-between">
                  <Link
                    className="mb-4 d-block tp-btn-xl green-anim"
                    to="/contacto"
                    onClick={scrollToTop}
                  >
                    Contáctanos
                  </Link>
                  {asset_link && (
                    <Link
                      className="mb-4 d-block tp-btn-xl green-anim"
                      to={asset_link}
                      target="_blank"
                    >
                      Ver más
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Description;
