import photo_1 from "../../assets/images/tourism/photo-1.jpg";
import photo_2 from "../../assets/images/tourism/photo-2.jpg";
import photo_3 from "../../assets/images/tourism/photo-3.png";
import photo_4 from "../../assets/images/tourism/photo-4.jpg";
import photo_5 from "../../assets/images/tourism/photo-5.jpg";
import photo_6 from "../../assets/images/tourism/photo-6.jpg";
import photo_7 from "../../assets/images/tourism/photo-7.jpg";
import photo_8 from "../../assets/images/tourism/photo-8.jpg";
import photo_9 from "../../assets/images/tourism/photo-9.jpg";
import photo_10 from "../../assets/images/tourism/photo-10.jpg";
import photo_11 from "../../assets/images/tourism/photo-11.jpg";
import photo_12 from "../../assets/images/tourism/photo-12.jpg";
import photo_13 from "../../assets/images/tourism/photo-13.jpg";

import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

const data = [
  {
    photo: photo_1,
    title: "Catarata Huamamachay (Saraús)",
  },
  {
    photo: photo_2,
    title: "Gastronomía",
  },
  {
    photo: photo_3,
    title: "Laguna La Quihuila",
  },
  {
    photo: photo_4,
    title: "Paisajes Naturales",
  },
  {
    photo: photo_5,
    title: "Fiesta de San Juan Bautista",
  },
  {
    photo: photo_6,
    title: "Plaza de Armas de Pallán",
  },
  {
    photo: photo_7,
    title: "Municipio de Pallán",
  },
  {
    photo: photo_8,
    title: "Plaza de Armas de Pallán",
  },
  {
    photo: photo_9,
    title: "Plaza de Armas de Pallán",
  },
  {
    photo: photo_10,
    title: "Plaza de Armas de Pallán",
  },
  {
    photo: photo_11,
    title: "Plaza de Armas de Pallán",
  },
  {
    photo: photo_12,
    title: "Plaza de Armas de Pallán",
  },
  {
    photo: photo_13,
    title: "Plaza de Armas de Pallán",
  },
];
function Gallery() {
  return (
    <div className="tp-project-area p-relative tp-project-2-plr pb-50">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <Swiper
              className="swiper-container"
              modules={[Pagination, Navigation]}
              navigation={{
                prevEl: ".photos-prev",
                nextEl: ".photos-next",
              }}
              slidesPerView={4}
              spaceBetween={30}
              speed={1000}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 4,
                },
              }}
            >
              <div className="tp-slider-photos-box">
                <button className="photos-prev">
                  <i className="fa-regular fa-arrow-left" />
                </button>
                <button className="photos-next">
                  <i className="fa-regular fa-arrow-right" />
                </button>
              </div>

              {data.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="tp-project-2-item p-relative text-center">
                    <div className="fix">
                      <img src={item.photo} alt="gallery item" loading="lazy" />
                    </div>
                    <div className="tp-project-2-content d-flex align-items-center justify-content-center">
                      <div>
                        <i className="flaticon-spark" />
                        <h4 className="tp-project-2-title-sm">{item.title}</h4>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
