import { Link } from "react-router-dom";
import error_img from "../../assets/images/others/error.png";
import { scrollToTop } from "../../utils/scrollUtils";
import { Helmet } from "react-helmet";

function NotFound() {
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === "true";

  if (isMaintenanceMode) {
    return (
      <>
        <Helmet>
          <title>Página en mantenimiento</title>
        </Helmet>
        <div className="tp-errror-area pt-40 pb-60">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="tp-errror-wrap text-center">
                  <h4 className="text-bottom">
                    Estamos en mantenimiento, por favor vuelve más tarde.
                  </h4>
                  <img src={error_img} alt="error" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>404 - Página no encontrada</title>
      </Helmet>
      <div className="tp-errror-area pt-40 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="tp-errror-wrap text-center">
                <h4 className="text-bottom">
                  Lo sentimos, no pudimos encontrar la página que estás
                  buscando.
                </h4>
                <img src={error_img} alt="error" />
                <Link
                  className="tp-btn-xl green-anim"
                  to="/"
                  onClick={scrollToTop}
                >
                  Regresar al inicio
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound;
