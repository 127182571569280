import { useState } from "react";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../../utils/appName";

function PrivacyPolicy() {
  // Manejo de estado para el acordeón
  const [activeItem, setActiveItem] = useState(0);

  const handleAccordionClick = (index) => {
    setActiveItem(index === activeItem ? null : index);
  };
  return (
    <>
      <Helmet>
        <title>
          Política de Privacidad - {APP_NAME}
        </title>
      </Helmet>
      <div className="tp-faq-area pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="mb-50 text-center">
              <span className="tp-section-subtitle-2">
                Conoce nuestra política de privacidad
              </span>
              <h4 className="tp-section-title text-decoration-underline">
                Política de Privacidad
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="tp-custom-accordion">
                <div className="accordion" id="accordionExample">
                  {accordionData.map((item, index) => (
                    <div
                      key={index}
                      className={`accordion-items ${
                        index === activeItem ? "tp-faq-active" : ""
                      }`}
                      onClick={() => handleAccordionClick(index)}
                    >
                      <h2 className="accordion-header">
                        <button
                          className={`accordion-buttons ${
                            index !== activeItem ? "collapsed" : ""
                          }`}
                          type="button"
                        >
                          {item.question}
                        </button>
                      </h2>
                      <div
                        id={`collapse${index + 1}`}
                        className={`accordion-collapse collapse ${
                          index === activeItem ? "show" : ""
                        }`}
                      >
                        <div className="accordion-body">
                          {/* Renderizando saltos de línea con <br /> */}
                          {item.answer.split("\n").map((line, i) => (
                            <p key={i}>{line}</p>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const accordionData = [
  {
    question: "Política de Privacidad",
    answer:
      "La presente Política de Privacidad establece los términos en que la Municipalidad Distrital de La Libertad de Pallán utiliza y protege la información proporcionada por sus usuarios al utilizar su sitio web. La municipalidad se compromete con la seguridad de los datos de sus usuarios. Al completar campos de información personal, se asegura que esta información se utilizará de acuerdo con los términos de este documento. Esta Política de Privacidad está sujeta a cambios, por lo que se recomienda revisar periódicamente esta página para estar al tanto de dichas modificaciones.",
  },
  {
    question: "Información Recopilada",
    answer:
      "Nuestro sitio web puede recolectar información personal como nombres, información de contacto como direcciones de correo electrónico, y datos demográficos. Esta información puede ser requerida para procesar trámites o solicitudes específicas.",
  },
  {
    question: "Uso de la Información Recopilada",
    answer:
      "El sitio web utiliza la información para brindar el mejor servicio posible, mantener registros de usuarios y pedidos, así como mejorar productos y servicios. También podrían enviarse correos electrónicos con ofertas especiales, nuevos productos u otra información que consideremos relevante para el usuario, permitiendo cancelar la suscripción en cualquier momento. La Municipalidad se compromete a mantener la seguridad de la información, utilizando sistemas avanzados para prevenir accesos no autorizados.",
  },
  {
    question: "Cookies",
    answer:
      "Las cookies son ficheros que, al ser aceptados en su ordenador, proveen información sobre el tráfico web y facilitan visitas futuras. Permiten a las webs reconocerlo y brindar un servicio personalizado. Nuestro sitio web usa cookies para identificar páginas visitadas y su frecuencia, empleando esta información únicamente para análisis estadístico. Pueden ser eliminadas en cualquier momento desde su ordenador, sin otorgar acceso a información personal a menos que sea proporcionada directamente. Usted puede aceptar o rechazar su uso, modificando la configuración de su navegador.",
  },
  {
    question: "Enlaces a Terceros",
    answer:
      "El sitio web puede contener enlaces a otros sitios. Al abandonar nuestra página, ya no tenemos control sobre la información proporcionada en esos sitios y no somos responsables de sus políticas de privacidad. Le recomendamos revisar las políticas de privacidad de esos sitios.",
  },
  {
    question: "Control de la Información Personal",
    answer:
      "Usted puede restringir la recopilación o el uso de información personal proporcionada al sitio web. En formularios, puede optar por recibir o no información por correo electrónico. El municipio no venderá, cederá ni distribuirá información personal sin su consentimiento, salvo por requerimiento judicial. La Municipalidad se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.",
  },
];

export default PrivacyPolicy;
