import { Helmet } from "react-helmet";
import PageBanner from "../../components/body/PageBanner";
import Description from "../../components/muni/Description";
import office_img from "../../assets/images/tourism/photo-7.jpg";
import { APP_NAME } from "../../utils/appName";

function MainOffice() {
  return (
    <>
      <Helmet>
        <title>
          Sede Central - {APP_NAME}
        </title>
      </Helmet>
      <PageBanner
        title="Municipalidad"
        subtitle="Sede Central"
        parent_link="/municipalidad"
      />
      <Description
        mainImage={office_img}
        span="Ubicación de la Municipalidad"
        title="Nuestra Sede Central"
        subtitle="Visita nuestra oficina principal"
        description="La Municipalidad Distrital de La Libertad de Pallán, se encuentra ubicada en la Plaza de Armas del distrito, en el Jr. La Purísima N° 230, en el departamento de Cajamarca, provincia de Celendín, distrito de La Libertad de Pallán."
      />
    </>
  );
}

export default MainOffice;
