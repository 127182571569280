import Map from "../../components/muni/Map";

function Location() {
  return (
    <>
      <div className="evn-details-area pt-80 pb-50">
        <div className="container">
          <div className="row">
            <div className="mb-30 text-center">
              <span className="tp-section-subtitle-2">
                Datos Generales del Distrito
              </span>
              <h4 className="tp-section-title text-decoration-underline">
                Ubicación y Clima
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-8">
              <div className="evn-text-box mb-30">
                <h4 className="tp-inner-title">Ubicación Geográfica</h4>
                <p>
                  El Distrito de la Libertad de Pallán se encuentra ubicada en
                  la parte norte del país; en el Departamento de Cajamarca, al
                  Noreste de la provincia de Celendín, a una altitud de 2934
                  msnm, con una superficie de 184.10 km2, está conformado por 27
                  caseríos y 3 centros poblados.
                </p>
              </div>
              <div className="evn-list-box">
                <h4 className="tp-inner-title mb-20">Clima</h4>
                <ul>
                  <li>
                    <span>
                      <i className="fa-regular fa-check" />
                    </span>
                    <p>Temperatura máxima 15°C - 18°C</p>
                  </li>
                  <li>
                    <span>
                      <i className="fa-regular fa-check" />
                    </span>
                    <p>Temperatura mínima: 7°C - 11°C</p>
                  </li>
                  <li>
                    <span>
                      <i className="fa-regular fa-check" />
                    </span>
                    <p>Temperatura promedio: 12.5°C</p>
                  </li>
                  <li>
                    <span>
                      <i className="fa-regular fa-check" />
                    </span>
                    <p>Época de lluvias: Octubre - Mayo</p>
                  </li>
                  <li>
                    <span>
                      <i className="fa-regular fa-check" />
                    </span>
                    <p>Época de estiaje: Junio - Septiembre</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="evn-sidebar">
                <div className="evn-user-wrap">
                  <div className="evn-user-item d-flex align-items-start">
                    <div className="evn-user-icon">
                      <span>
                        <i className="fa-sharp fa-regular fa-signs-post"></i>
                      </span>
                    </div>
                    <div className="evn-user-info">
                      <p>Ubicación:</p>
                      <span>Celendín - Cajamarca - Perú</span>
                    </div>
                  </div>
                  <div className="evn-user-item d-flex align-items-start">
                    <div className="evn-user-icon">
                      <span>
                        <i className="fa-regular fa-location-dot" />
                      </span>
                    </div>
                    <div className="evn-user-info">
                      <p>Coordenadas:</p>
                      <span>
                        <a
                          href="https://maps.app.goo.gl/g1u1MoNCQzDnygMR6"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          6°43′17″S 78°17′13″O
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="evn-user-item d-flex align-items-start">
                    <div className="evn-user-icon">
                      <span>
                        <i className="fa-regular fa-calculator"></i>
                      </span>
                    </div>
                    <div className="evn-user-info">
                      <p>Superficie:</p>
                      <span>184.1 km²</span>
                    </div>
                  </div>
                  <div className="evn-user-item d-flex align-items-start">
                    <div className="evn-user-icon">
                      <span>
                        <i className="fa-regular fa-mountain" />
                      </span>
                    </div>
                    <div className="evn-user-info">
                      <p>Altitud:</p>
                      <span>2934 m.s.n.m.</span>
                    </div>
                  </div>
                  <div className="evn-user-item d-flex align-items-start">
                    <div className="evn-user-icon">
                      <span>
                        <i className="fa-solid fa-person"></i>
                      </span>
                    </div>
                    <div className="evn-user-info">
                      <p>Población:</p>
                      <span>6434 Habitantes (Censo 2017)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Map />
    </>
  );
}

export default Location;
