import { Link } from "react-router-dom";

import default_image from "../../assets/images/history/history-4.png";
import { scrollToTop } from "../../utils/scrollUtils";

const Folder = ({ title, link }) => (
  <Link
    to={link}
    className="col-xl-4 col-lg-4 col-md-6 mb-30 wow tpfadeUp"
    onClick={scrollToTop}
  >
    <div
      className="tp-service-standard-item p-relative"
      style={{
        visibility: "visible",
        animationDuration: "0.9s",
        animationDelay: "0.3s",
        animationName: "tpfadeUp",
      }}
    >
      <div className="tp-service-standard-img">
        <img src={default_image} alt="default_image" />
      </div>
      <div className="tp-service-standard-content-wrap z-index">
        <div className="tp-service-standard-content-main">
          <div className="tp-service-standard-content">
            <span>
              <i className="flaticon-goverment" />
            </span>
            <h4 className="tp-service-standard-title">
              <Link to={link} onClick={scrollToTop}>
                {title}
              </Link>
            </h4>
          </div>
          <div className="tp-service-standard-link">
            <Link to={link} onClick={scrollToTop}>
              Ver más <i className="flaticon-right-arrow" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Link>
);

function Folders({ items }) {
  return (
    <div className="tp-service-area tp-service-2-mlr pt-80 pb-50 z-index-2">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="tp-service-title-box text-center mb-70">
              <span className="tp-section-subtitle">
                Documentos para el trabajo municipal
              </span>
              <h4 className="tp-section-title">
                Nuestros instrumentos de gestión
              </h4>
            </div>
          </div>
        </div>
        <div className="row align-items-end">
          {items.map((item, index) => (
            <Folder key={index} link={item.path} title={item.title} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Folders;
