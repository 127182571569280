import emailjs from "@emailjs/browser";

const REACT_APP_SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const REACT_APP_TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const REACT_APP_PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

export const responseEmail = (e, form) => {
  e.preventDefault();

  emailjs
    .sendForm(REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, form.current, {
      publicKey: REACT_APP_PUBLIC_KEY,
    })
    .then(
      () => {
        console.log("¡Email enviado con éxito!");
      },
      (error) => {
        console.log("¡Error al enviar el email!", error.text);
      }
    )
    .catch((error) => {
      console.log("¡Error al enviar el email!", error);
    });
};
