import { Helmet } from "react-helmet";
import PageBanner from "../../../components/body/PageBanner";
import { APP_NAME } from "../../../utils/appName";

function InstrumentsList({ folder = null, Data }) {
  // Define la prop aquí
  return (
    <>
      <Helmet>
        <title>Instrumentos de gestión - {APP_NAME}</title>
      </Helmet>
      <PageBanner
        title="Municipalidad"
        subtitle="Instrumentos de gestión"
        parent_link="/municipalidad"
        subtitle_link="/instrumentos-de-gestion"
        folder={folder}
      />
      <Data />
    </>
  );
}

export default InstrumentsList;
